.default_color{
  background: linear-gradient(230deg, #ef4236 -5.19%, #faa93f 109.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.default_color_icon {
    color: #f9983f;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 30px;
    width: 30px;
}